export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_PROVICE = process.env.REACT_APP_PROVICE_API;
export const BASE_URL_IMAGE = process.env.REACT_APP_BASE_URL_IMAGE;
export const REACT_APP_BASE_GRAPHQL_URL =
  process.env.REACT_APP_BASE_GRAPHQL_URL;
export const BASE_URL_STRAPI = process.env.REACT_APP_BASE_URL_STRAPI;
export const CONVERT_BASE_URL = process.env.REACT_APP_CONVERT_BASE_URL;

const admin = "admin";
const logIn = "auth/login";
const user = "users";
const role = "roles";
const school = "schools";
const classroom = "classrooms";
const province = "provinces";
const district = "districts";
const award = "awards";
const setpass = "users/set-password";
const changepass = "users/change-password";
const course = "courses";
const payment = "paymentPackages";
//HDH
const section = "sections";
const questionbank = "questionbanks";
const examquestion = "examQuestions";
const field = "fields";
const examgroup = "sectionGroups";
const examattempt = "examAttempts";
const refresh = "refresh-tokens";
//DTA
const typeshared = "typeGroups";
const statisticals = "statisticals/pigs";
const statisticalByHerd = "statisticals/pigsByHerd";
const shareddata = "sharedDatas";
const configs = "configs";
const feedback = "feedbacks";
const subscribers = "users/subscribers";
const servicePackages = "servicePacks";
const serviceTimes = "serviceTimes";
const promotions = "promotions";
const products = "products";
const productTemps = "productTemps";
const promotionDetails = "promotionDetails";
const growthParameterss = "growthParameterss";
const typeGroups = "typeGroups";
const procedure = "actionGroups/procedures";
const herdTypes = "herdTypes";
const herds = "herds";
const herdGuest = "herds/guest/create";
const contract = "contracts";
const caculateContract = "contracts/caculate";
const actionGroups = "actionGroups";
const actions = "actions";
const events = "events";
const parametters = "parametters";
const pigs = "pigs";
const pigHistorys = "pigHistorys";
const pigByBarn = "pigs/guest/pigsByHerd";
const pigByUserId = "herds/guest/overview";

export default {
  BASE_URL,
  API_PROVICE,
  admin,
  logIn,
  user,
  role,
  school,
  classroom,
  province,
  district,
  award,
  setpass,
  changepass,
  course,
  payment,
  section,
  questionbank,
  field,
  examquestion,
  examgroup,
  examattempt,
  refresh,
  typeshared,
  shareddata,
  configs,
  feedback,
  subscribers,
  servicePackages,
  serviceTimes,
  promotions,
  growthParameterss,
  typeGroups,
  procedure,
  products,
  herdTypes,
  promotionDetails,
  contract,
  productTemps,
  herds,
  statisticals,
  herdGuest,
  actionGroups,
  events,
  actions,
  parametters,
  pigs,
  pigByBarn,
  statisticalByHerd,
  pigHistorys,
  pigByUserId,
  caculateContract,
};
