import {
  Button,
  Form,
  Input,
  Radio,
  Select,
  Col,
  DatePicker,
  Row,
  notification,
} from "antd";
import NumberFormat from "react-currency-format";
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { PayloadType, SelectOptionType } from "type";
import { closeModal, getYupSync } from "utils";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { getPromotionDetails } from "features/promotionDetailSlice";
import _ from "lodash";
import queryString from "query-string";

import { getSubscribers } from "features/subscriberSlice";
import { getPromotions } from "features/promotionsSlice";
import { getServicePackages } from "features/servicePackageSlice";
import moment from "moment";
const dateFormat = "DD/MM/YYYY";
import {
  caculateContract,
  createContracts,
  getContracts,
  updateContracts,
} from "features/contractSlice";
function CUContract({ idUpdate, record }: any) {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { query } = useSelector((state: any) => state.contractReducer);
  const [loading, setLoading] = useState(false);
  const { open } = useSelector((state: any) => state.modalReducer);
  const [listServicePack, setListServicePacks] = useState<SelectOptionType[]>(
    []
  );
  const [listPromotion, setListPromotion] = useState<SelectOptionType[]>([]);
  const [listPromotionDetail, setListPromotionDetail] = useState<
    SelectOptionType[]
  >([]);
  const [listUser, setListUser] = useState<SelectOptionType[]>([]);
  const [timeStart, setTimeStart] = useState(false);

  // load danh sách hợp đồng sau khi tạo mới
  function getAllContract() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getContracts(payload));
  }

  //Lấy danh sách tài khoản người dùng
  function handleGetUser() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          limit: 99999,
          populate: "personInChargeId, personalConfig. farmScaleId farmTypeId",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(val) {
          const results = _.get(val, "results", []);
          if (!!results && Array.isArray(results)) {
            setListUser(
              results.map((item: any) => {
                return {
                  label: item.phone,
                  value: item.id,
                  ...item,
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getSubscribers(payload));
  }

  useEffect(() => {
    handleGetUser();
  }, []);

  // lấy danh sách  gói dịch vụ
  const getListServicePacks = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 9999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          if (!!results && Array.isArray(results)) {
            setListServicePacks(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách admin! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getServicePackages(payload));
  };
  useEffect(() => {
    getListServicePacks();
  }, []);

  // lấy danh sách gói khuyến mãi
  const getListPromotion = (dateStart: string) => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 9999,
        date: dateStart,
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          if (!!results && Array.isArray(results)) {
            setListPromotion(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách admin! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getPromotions(payload));
  };

  // Lấy chi tiết gói khuyến mãi theo ID của chương trình khuyến mãi
  const getListPromotionDetail = (idPromotion: string) => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 9999,
        promotionId: idPromotion,
        populate: "servicePackId, promotionId",
        date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          if (!!results && Array.isArray(results)) {
            setListPromotionDetail(
              results.map((item: any) => {
                return {
                  label: item.code,
                  value: item.id,
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách admin! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getPromotionDetails(payload));
  };

  const formSchema = yup.object().shape({
    userId: yup.string().required("Tên đăng nhập không được để trống!"),
    startDateContract: yup
      .string()
      .required("Vui lòng chọn thời gian bắt đầu!"),

    servicePackageIds: yup
      .array()
      .of(yup.string())
      .required("Vui lòng chọn gói dịch vụ")
      .min(1, "Vui lòng chọn ít nhất 1 dịch vụ"),
  });

  useEffect(() => {
    if (!open) {
      form.resetFields();
    } else if (idUpdate) {
      console.log("record", record);
      form.setFieldsValue(record);
      form.setFieldValue("endDateContract", moment(record?.endDateContract));
      form.setFieldValue("promotionId", record?.promotionId?.id);
      form.setFieldValue(
        "promotionDetailId",
        record?.promotionDetailApprovedId?.id
      );
      form.setFieldValue(
        "startDateContract",
        moment(record?.startDateContract)
      );
      form.setFieldValue("userId", record.userId.id);
      form.setFieldValue("name", record?.userId?.name);
      form.setFieldValue(
        "timeUse",
        moment(record?.endDateContract).diff(
          moment(record?.startDateContract),
          "months"
        )
      );
      form.setFieldValue(
        "servicePackageIds",
        record?.servicePackageIds.map((item: any) => item?._id)
      );
      getListPromotionDetail(form.getFieldValue("promotionId"));
    }
  }, [record, open]);

  const yupSync = [getYupSync(formSchema)];

  async function handleSubmit() {
    const values = form.getFieldsValue([
      "userId",
      "servicePackageIds",
      "promotionId",
      "promotionDetailId",
      "startDateContract",
      "status",
    ]);
    if (!idUpdate) {
      handleCreateConstract(values);
      return;
    }
    handleUpdateConstract(values);
  }

  function handleCreateConstract(values: any) {
    setLoading(true);
    const payload: PayloadType = {
      body: {
        servicePackageIds: _.get(values, "servicePackageIds", []),
        promotionId: _.get(values, "promotionId", null),
        promotionDetailId: _.get(values, "promotionDetailId", null),
        startDateContract: moment(
          _.get(values, "startDateContract", "")
        ).toISOString(), // iso date
        userId: _.get(values, "userId", ""),
      },
      callback: {
        success(values) {
          notification.success({
            message: "Tạo mới hợp đồng thành công!",
          });
          form.resetFields();
          getAllContract();
          closeModal(dispatch, setModal);
          setLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể  tạo mới hợp đồng!",
            description: errorMessage,
          });
          setLoading(false);
        },
      },
    };
    dispatch(createContracts(payload));
  }
  function handleUpdateConstract(values: any) {
    setLoading(true);
    const payload: PayloadType = {
      params: idUpdate,
      body: {
        servicePackageIds: _.get(values, "servicePackageIds", []),
        promotionId: _.get(values, "promotionId", null),
        promotionDetailId: _.get(values, "promotionDetailId", null),
        startDateContract: moment(
          _.get(values, "startDateContract", "")
        ).toISOString(), // iso date
        status: values.status,
      },
      callback: {
        success(values) {
          notification.success({
            message: "Cập nhật thông tin hợp đồng thành công!",
          });
          getAllContract();
          closeModal(dispatch, setModal);
          form.resetFields();
          setLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể cập nhật hợp đồng người dùng!",
            description: errorMessage,
          });
          setLoading(false);
        },
      },
    };
    dispatch(updateContracts(payload));
  }

  const caculateDataServices = () => {
    const values = form.getFieldsValue([
      "userId",
      "servicePackageIds",
      "promotionId",
      "promotionDetailId",
      "startDateContract",
    ]);
    if (
      !_.isUndefined(form.getFieldValue("servicePackageIds")) &&
      !_.isUndefined(form.getFieldValue("startDateContract"))
    ) {
      console.log("hienr thi oke now");
      if (_.isUndefined(form.getFieldValue("promotionId"))) {
        form.setFieldValue("promotionId", null);
      }
      const bodyCaculate = {
        servicePackageIds: _.get(values, "servicePackageIds", []),
        promotionId: _.get(values, "promotionId", null),
        promotionDetailId: _.get(values, "promotionDetailId", null),
        startDateContract: moment(values?.startDateContract).toISOString(), // iso date
      };
      const payload: PayloadType = {
        body: bodyCaculate,
        callback: {
          success(val) {
            form.setFieldValue("timeUse", val.numOfMonth);
            form.setFieldValue("endDateContract", moment(val.endDateContract));
            form.setFieldValue("remainMoney", val.remainMoney);
          },
          failed(errorMessage) {
            notification.error({
              message: "Có lỗi xảy ra trong quá trình lấy danh sách! ",
              description: errorMessage,
            });
          },
        },
      };
      dispatch(caculateContract(payload));
    }
  };

  const initValues = idUpdate
    ? {
        record,
      }
    : {
        userId: undefined,
        servicePackageIds: [],
        promotionId: undefined,
        promotionDetailId: undefined,
        startDateContract: undefined,
        name: undefined,
        timeUse: undefined,
        endDateContract: undefined,
        remainMoney: "",
      };

  useEffect(() => {
    if (!idUpdate) {
      form.setFieldsValue(initValues);
    }
  }, [idUpdate]);

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      onFinish={() => {
        handleSubmit();
      }}
      initialValues={initValues}
      autoComplete="off"
    >
      <div className="ant-modal-body">
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              required
              label={"Tên đăng nhập"}
              name={"userId"}
              rules={yupSync}
            >
              <Select
                showSearch
                allowClear
                disabled={!!idUpdate}
                style={{ width: "100%" }}
                placeholder="Nhập SĐT để tìm kiếm"
                optionFilterProp="children"
                onChange={(e: any) => {
                  const dataNameByUser: any = listUser.find(
                    (item) => item.id === e
                  );
                  const nameUser = _.get(dataNameByUser, "name", "");
                  form.setFieldValue("name", nameUser);
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={listUser}
              />
            </Form.Item>

            <Form.Item label={"Tên chủ trại"} name={"name"}>
              <Input placeholder="" disabled style={{ color: "#000" }} />
            </Form.Item>

            <Form.Item
              required
              label={"Tên dịch vụ"}
              name={"servicePackageIds"}
              rules={yupSync}
            >
              <Select
                mode="multiple"
                showSearch
                allowClear
                style={{ width: "100%" }}
                placeholder="Chọn dịch vụ"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                onChange={caculateDataServices}
                options={listServicePack}
              />
            </Form.Item>

            <Form.Item
              required
              label={"Thời gian ký hợp đồng"}
              name={"startDateContract"}
              rules={yupSync}
            >
              <DatePicker
                style={{ width: "100%", padding: "0px" }}
                allowClear
                placeholder="Chọn thời gian bắt đầu"
                format={dateFormat}
                onChange={() => {
                  caculateDataServices();
                  getListPromotion(
                    form
                      .getFieldValue("startDateContract")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                  );
                }}
              />
            </Form.Item>

            <Form.Item label={"Chương trình khuyến mãi"} name={"promotionId"}>
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                onChange={() => {
                  setTimeStart(!timeStart);
                  getListPromotionDetail(form.getFieldValue("promotionId"));
                  caculateDataServices();
                }}
                placeholder="Nhập tên chương trình KM để tìm kiếm"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={listPromotion}
              />
            </Form.Item>

            <Form.Item label={"Chi tiết KM"} name={"promotionDetailId"}>
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                onChange={() => {
                  setTimeStart(!timeStart);
                  caculateDataServices();
                }}
                placeholder="Nhập tên chi tiết KM để tìm kiếm"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={listPromotionDetail}
              />
            </Form.Item>

            <Form.Item label={"Thời gian dịch vụ (tháng)"} name={"timeUse"}>
              <Input disabled style={{ color: "#000" }} />
            </Form.Item>

            <Form.Item label={"Thời gian hết hạn"} name={"endDateContract"}>
              <DatePicker
                format={dateFormat}
                disabled
                style={{ color: "#000", width: "100%", padding: "0px" }}
              />
            </Form.Item>

            <Form.Item label={"Tổng tiền TT (vnđ)"} name={"remainMoney"}>
              <NumberFormat
                thousandSeparator={"."}
                decimalSeparator={","}
                disabled={true}
                style={{ border: "1px solid #d9d9d9" }}
              />
            </Form.Item>

            <Form.Item
              label={"Trạng thái"}
              name={"status"}
              hidden={idUpdate ? false : true}
            >
              <Radio.Group>
                <Radio value={"doing"}>Đang thực hiện</Radio>
                <Radio value={"close"}>Đóng hợp đồng</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="ant-modal-footer">
        <Button
          type="default"
          onClick={() => {
            closeModal(dispatch, setModal);
            form.resetFields();
          }}
        >
          Huỷ
        </Button>
        <Button
          onClick={() => {
            if (!loading) form.submit();
          }}
          type="primary"
          loading={loading}
        >
          {idUpdate ? "Xác nhận" : "Thêm mới"}
        </Button>
      </div>
    </Form>
  );
}

export default CUContract;
