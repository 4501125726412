/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { ServicePack } from "type";

/*
 * Declare type of data
 */
interface ServicePackState {
  servicePackages: ServicePack[]; //
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: ServicePackState = {
  servicePackages: [],
  isLoading: false,
  query: {
    page: 1,
    limit: 10,
  },
};

/**
 *  Slice manager service pack
 **/

export const servicePackageSlice: any = createSlice({
  name: "servicePackageSlice",
  initialState: initialState,
  reducers: {
    getServicePackages: (state, action) => {
      state.isLoading = true;
    },
    getServicePackagesSuccess: (state, action) => {
      state.servicePackages = action.payload;
      state.isLoading = false;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    createServicePackages: (state, action) => {
      state.isLoading = true;
    },
    updateServicePackages: (state, action) => {
      state.isLoading = true;
    },
    deleteServicePackages: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  createServicePackages,
  updateServicePackages,
  deleteServicePackages,
  getServicePackages,
  getServicePackagesSuccess,
  setQuery,
} = servicePackageSlice.actions;
export default servicePackageSlice.reducer;
