/**
 * Giao diện hiển thị danh sách hợp đồng khách hàng
 */
import { useState, useEffect } from "react";
import moment from "moment";
import {
  Button,
  Popconfirm,
  Table,
  Typography,
  notification,
  DatePicker,
  Tabs,
  Input,
  Space,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
const { Search } = Input;
import { ModalInfoType, PayloadType } from "type";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "features/modalSlice";
import _ from "lodash";
import queryString from "query-string";
import CUContract from "./CUContract";
import { getContracts, setQuery } from "features/contractSlice";
import RContract from "./RContract";
const { Title } = Typography;

function ListContract() {
  const dispatch = useDispatch();
  const { query, contracts } = useSelector(
    (state: any) => state.contractReducer
  );
  const [searchText, setSearchText] = useState<string>("");

  //Lấy danh sách hợp đồng
  function getAllContract() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          populate: "servicePackageIds",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getContracts(payload));
  }
  useEffect(() => {
    getAllContract();
  }, [query]);

  const handleSearch = () => {
    dispatch(
      setQuery({
        ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        searchValue: searchText,
        page: 1, //Trường lọc data là name và
      })
    );
  };

  const filterByYear = (e: any) => {
    const year =
      _.isNull(e) || _.isNaN(e) || _.isUndefined(e) ? "" : moment(e).year();
    dispatch(
      setQuery({
        ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        year: year, //Trường lọc data là name và
      })
    );
  };
  const filterByMonthAndYear = (e: any) => {
    const year =
      _.isNull(e) || _.isNaN(e) || _.isUndefined(e) ? "" : moment(e).year();
    const month =
      _.isNull(e) || _.isNaN(e) || _.isUndefined(e)
        ? ""
        : moment(e).month() + 1;
    dispatch(
      setQuery({
        ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        year: year,
        month: month, //Trường lọc data là name và
      })
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: "Mã HĐ",
      dataIndex: "code",
      key: "code",
      width: 150,
    },
    {
      title: "Tên chủ trại",
      dataIndex: "userId",
      key: "userId",
      width: 450,
      render: (val, record) => {
        return <>{val.name}</>;
      },
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "userId",
      key: "userId",
      width: 250,
      render: (val, record) => {
        return <>{val.phone}</>;
      },
    },
    {
      title: "Mã dịch vụ",
      dataIndex: "servicePackageIds",
      key: "servicePackageIds",
      width: 200,
      render: (val, record) => {
        return <>{val.map((item: any) => item.name).join(", ")}</>;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (val) => {
        if (val === "doing") {
          return <div>Đang hoạt động</div>;
        } else {
          return <div style={{ color: "red" }}>Đã đóng</div>;
        }
      },
    },

    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "left",
      width: 200,
      render: (_, record: any) => {
        return (
          <>
            <Space size="middle">
              <a
                onClick={() => {
                  console.log("record", record);
                  const payload: ModalInfoType = {
                    open: true,
                    title: "Sửa hợp đồng khách hàng",
                    content: (
                      <CUContract idUpdate={record?.id} record={record} />
                    ),
                    size: 550,
                  };
                  dispatch(setModal(payload));
                }}
              >
                Sửa
              </a>
            </Space>

            <Space size="middle" style={{ paddingLeft: "30px" }}>
              <a
                onClick={() => {
                  const payload: ModalInfoType = {
                    open: true,
                    title: "Xem hợp đồng khách hàng",
                    content: <RContract record={record} />,
                    size: 650,
                  };
                  dispatch(setModal(payload));
                }}
              >
                Xem
              </a>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <DatePicker
            picker="year"
            placeholder="Chọn năm HĐ"
            onChange={(e) => filterByYear(e)}
          />
          <DatePicker
            picker="month"
            placeholder="Chọn tháng HĐ"
            onChange={(e) => filterByMonthAndYear(e)}
          />
          <Search
            placeholder="Tìm kiếm theo tên đăng nhập "
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={handleSearch}
            style={{ width: 242 }}
          />
          <Button
            type="primary"
            onClick={() => {
              const payload: ModalInfoType = {
                open: true,
                title: "Thêm mới hợp đồng khách hàng",
                content: <CUContract idUpdate={undefined} />,
                size: 550,
              };
              dispatch(setModal(payload));
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>{" "}
      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={_.get(contracts, "results", [])}
        pagination={{
          total: _.get(contracts, "totalResults", []),
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
    </>
  );
}

export default ListContract;
