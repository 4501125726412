import { Button, Form, Input, Radio, Select, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { useDispatch } from "react-redux";
import { PayloadType, Role, SelectOptionType, UserType } from "type";
import { closeModal, getBody, getYupSync } from "utils";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { getRoles } from "features/roleSlice";
import _ from "lodash";
import {
  createServicePackages,
  updateServicePackages,
  getServicePackages,
  setQuery,
} from "features/servicePackageSlice";
import queryString from "query-string";
import { useSelector } from "react-redux";
interface DataType {
  name: string;
  time: string;
  price: number;
  numOfHerd: number;
  numOfPigsty: number;
  numOfPig: number;
}
interface UserInfo {
  idUpdate?: string;
  record?: DataType;
}
function CUService({ idUpdate = undefined, record = undefined }: UserInfo) {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { query } = useSelector((state: any) => state.servicePackageReducer);
  const { open } = useSelector((state: any) => state.modalReducer);
  const [listRoles, setListRoles] = useState<SelectOptionType[]>([]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
    form.setFieldValue("name", _.get(record, "name", ""));
    form.setFieldValue("time", _.get(record, "time", ""));
    form.setFieldValue("price", _.get(record, "price", ""));
    form.setFieldValue("numOfBarn", _.get(record, "numOfBarn", ""));
    form.setFieldValue("numOfFemalePig", _.get(record, "numOfFemalePig", ""));
  }, [record, open]);

  //Lấy danh sách tài khoản
  function handleGetServicePacks() {
    const payload: PayloadType = {
      query: queryString.stringify(query, {
        skipNull: true,
        skipEmptyString: true,
      }),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getServicePackages(payload));
  }
  const formSchema = yup.object().shape({
    name: yup.string().required("Vui lòng nhập tên gói dịch vụ!"),
    time: yup
      .number()
      .typeError("Vui lòng nhập số tháng !")
      .required("Vui lòng nhập số tháng !")
      .min(1, "Số tháng phải là số dương và lớn hơn 0"),

    price: yup
      .number()
      .typeError("Vui lòng nhập số tiền !")
      .required("Vui lòng nhập số tiền !")
      .min(1, "Số tiền là số dương và lớn hơn 0 "),

    numOfFemalePig: yup.string().required("Chọn tổng số thẻ tai nái !"),
    numOfBarn: yup.string().required("Chọn số chuồng tối đa !"),
  });
  const yupSync = [getYupSync(formSchema)];
  function handleSubmit() {
    const values = form.getFieldsValue([
      "name",
      "time",
      "price",
      "numOfBarn",
      "numOfFemalePig",
    ]);
    const valueInt = {
      ...values,
      numOfBarn: parseInt(values.numOfBarn),
      numOfFemalePig: parseInt(values.numOfFemalePig),
    };
    if (!idUpdate) {
      handleCreateServicePacks(valueInt);
      return;
    }
    handleUpdateListPacks(valueInt);
  }
  function handleCreateServicePacks(values: DataType) {
    const payload: PayloadType = {
      callback: {
        success(roles) {
          const results = _.get(roles, "results", null);
          if (!!results && _.isArray(results) && results.length > 0) {
            const payload: PayloadType = {
              body: {
                ...values,
              },
              callback: {
                success(values) {
                  notification.success({
                    message: "Tạo mới gói dịch vụ thành công!",
                  });
                  handleGetServicePacks();
                  closeModal(dispatch, setModal);
                },
                failed(errorMessage) {
                  notification.error({
                    message: "Không thể  tạo mới gói dịch vụ!",
                    description: errorMessage,
                  });
                },
              },
            };
            dispatch(createServicePackages(payload));
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể tạo mới gói dịch vụ!",
            description: "Thiếu quyền!",
          });
        },
      },
    };
    dispatch(getRoles(payload));
  }
  function handleUpdateListPacks(values: UserType) {
    const payload: PayloadType = {
      callback: {
        success(roles) {
          const results = _.get(roles, "results", null);
          if (!!results && _.isArray(results) && results.length > 0) {
            const payload: PayloadType = {
              params: idUpdate,
              body: {
                ...values,
              },

              callback: {
                success(values) {
                  notification.success({
                    message: "Cập nhật thông tin Admin thành công!",
                  });
                  handleGetServicePacks();
                  closeModal(dispatch, setModal);
                },
                failed(errorMessage) {
                  notification.error({
                    message: "Không thể cập nhật thông tin Admin!",
                    description: errorMessage,
                  });
                },
              },
            };
            dispatch(updateServicePackages(payload));
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể cập nhật thông tin Admin!",
            description: "Thiếu quyền!",
          });
        },
      },
    };
    dispatch(getRoles(payload));
  }

  useEffect(() => {
    if (idUpdate) {
    }
  }, [idUpdate]);
  const initValues = {
    name: undefined,
    numOfBarn: undefined,
    numOfFemalePig: undefined,
    price: undefined,
    time: undefined,
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      onFinish={() => {
        handleSubmit();
      }}
      initialValues={initValues}
      autoComplete="off"
    >
      <div className="ant-modal-body">
        <Form.Item
          required
          label={"Tên gói dịch vụ"}
          name={"name"}
          rules={yupSync}
        >
          <Input placeholder="Nhập vào tên gói dịch vụ" />
        </Form.Item>
        <Form.Item
          required
          label={
            <div
              style={{
                textAlign: "right",
                lineHeight: "15px",
                marginTop: "-3px",
              }}
            >
              Số chuồng tối đa
            </div>
          }
          name={"numOfBarn"}
          rules={yupSync}
        >
          <Select
            placeholder="Chọn số chuồng tối đa"
            options={[
              {
                label: "3 Chuồng",
                value: 3,
              },
              {
                label: "10 Chuồng",
                value: 10,
              },
              {
                label: "Không giới hạn",
                value: -1,
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          required
          label={
            <div
              style={{
                textAlign: "right",
                lineHeight: "15px",
                marginTop: "-3px",
              }}
            >
              Chọn tổng số thẻ tai nái
            </div>
          }
          name={"numOfFemalePig"}
          rules={yupSync}
        >
          <Select
            placeholder="Chọn tổng số thẻ tai"
            options={[
              {
                label: "Tối đa 100 con",
                value: 100,
              },
              {
                label: "Tối đa 250 con",
                value: 250,
              },
              {
                label: "Không giới hạn",
                value: -1,
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          required
          label={"Thời gian(tháng)"}
          name={"time"}
          rules={yupSync}
        >
          <Input placeholder="Nhập vào số tháng" />
        </Form.Item>

        <Form.Item
          required
          label={"Giá tiền(vnđ)"}
          name={"price"}
          rules={yupSync}
        >
          <Input placeholder="Nhập vào số tiền" />
        </Form.Item>
      </div>
      <div className="ant-modal-footer">
        <Button
          type="default"
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Huỷ
        </Button>
        <Button
          onClick={() => {
            form.submit();
          }}
          type="primary"
        >
          {idUpdate ? "Lưu lại" : "Thêm mới"}
        </Button>
      </div>
    </Form>
  );
}

export default CUService;
