/**
 * Tab Nhóm khác
 */
import { useState, useEffect } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  Form,
  Row,
  Col,
  Button,
  Popconfirm,
  Select,
  Table,
  Layout,
  Typography,
  notification,
  Switch,
  Input,
  Space,
} from "antd";
import CustomLabelValue from "pages/components/CustomLabelValue";
import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";
const { Content } = Layout;
import type { ColumnsType } from "antd/es/table";
const { Search } = Input;
import moment from "moment";
import { useHistory } from "react-router";
import {} from "antd";
import { closeModal } from "utils";
import { ModalInfoType, PayloadType } from "type";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import { useSelector } from "react-redux";
import _ from "lodash";
import { getPromotionDetails, setQuery } from "features/promotionDetailSlice";
import queryString from "query-string";
import CUPromotion from "./CUContract";
const { Title } = Typography;
interface DataType {
  phone: string;
  name: string;
  nameForPig: string;
  farmName: string;
  farmTypeId: string;
  farmScaleId: string;
  email: string;
  personInChargeId: string;
  address: string;
  provinceId: string;
  servicePackageId: string;
  serviceTimeId: string;
}
const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
import { useForm } from "antd/lib/form/Form";
function RContract(dataView: any) {
  const record = dataView?.record;
  const dispatch = useDispatch();
  const history = useHistory();
  const { query } = useSelector((state: any) => state.promotionDetailReducer);
  const [listPromotionDetail, setListPromotionDetail] = useState<any>([]);

  //Lấy danh sách chi tiết khuyến mãi theo id khuyến mãi
  function getDetailPromotionByID() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          promotionId: record?.id,
          populate: "promotionId",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {
          setListPromotionDetail(values);
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getPromotionDetails(payload));
  }
  useEffect(() => {
    getDetailPromotionByID();
  }, [query]);

  function formatCurrency(price: number, locale = "vi-VN", currency = "VND") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(price);
  }
  const [form] = useForm();

  return (
    <Content
      className="custom-layout-content background-content-layout"
      style={{
        marginLeft: 20,
        marginTop: 16,
        marginRight: 20,
        // backgroundColor: "white",
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Row
          style={{
            paddingTop: 16,
            paddingLeft: 20,
            paddingRight: 20,
            background: "rgba(242, 242, 242, 0.50)",
          }}
        >
          <Col span={24}>
            <CustomLabelValue
              hasMarginleft={false}
              alignX={200}
              label="Mã hợp đồng"
              value={_.get(record, "code", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={200}
              label="Tên chủ trại"
              value={_.get(record, "userId.name", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={200}
              label="Số điện thoại"
              value={_.get(record, "userId.phone", "")}
            />

            <CustomLabelValue
              hasMarginleft={false}
              alignX={200}
              label="Dịch vụ lựa chọn "
              value={record?.servicePackageIds
                .map((item: any) => item.name)
                .join(", ")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={200}
              label="Chương trình khuyến mãi "
              value={_.get(record, "promotionId.name", "")}
            />

            <CustomLabelValue
              hasMarginleft={false}
              alignX={200}
              label="Thời gian ký hợp đồng"
              value={moment(_.get(record, "startDateContract", "")).format(
                "DD/MM/YYYY"
              )}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={200}
              label="Thời gian dịch vụ (tháng)"
              value={moment(record?.endDateContract).diff(
                moment(record?.startDateContract),
                "months"
              )}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={200}
              label="Thời gian hết hạn "
              value={moment(_.get(record, "endDateContract", "")).format(
                "DD/MM/YYYY"
              )}
            />

            <CustomLabelValue
              hasMarginleft={false}
              alignX={200}
              label="Tổng tiền thanh toán: "
              value={formatCurrency(record?.totalMoney, "vi-VN", "VND")}
            />

            <CustomLabelValue
              hasMarginleft={false}
              alignX={200}
              label="Trạng thái: "
              value={
                _.get(record, "status", "") === "doing" ? "Hoạt động" : "Đóng"
              }
            />
          </Col>
        </Row>
        <div className="ant-modal-footer">
          <Button
            onClick={() => {
              closeModal(dispatch, setModal);
            }}
          >
            Quay lại
          </Button>
        </div>
      </Space>
    </Content>
  );
}

export default RContract;
